import { TipoDespesa } from './../tipo-despesa/tipo-despesa';
import { ContaCorrente } from '../conta-corrente/conta-corrente.model';
import { Fornecedor } from './../fornecedor/fornecedor.model';
import { BaseEntity } from 'src/app/core/domain/base.entity';
import { Suprimento } from '../suprimento/suprimento.model';
import { ObjetoDespesa } from '../objeto-despesa/objeto-despesa.model';

export class Despesa extends BaseEntity {
  fornecedor: Fornecedor;
  objetoDespesa: ObjetoDespesa;
  finalidade: string;
  descricao: string;
  contaCorrente: ContaCorrente;
  dataNf: Date;
  valorTotal: number;
  numeroNf: string;
  tipoDespesa: TipoDespesa;
  suprimento: Suprimento;
  ativo: boolean;
  dataCadastro: Date;
  dataAtualizacao: Date;
}
