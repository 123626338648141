import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { Despesa } from '../despesa/despesa.model';

const despesaAudit: ResourceConfig = {
  api: 'despesas',
  path: 'despesas',
  entity: Despesa.name,
  label: 'Despesas',
  showInPage: false,
  labelTemplate: `<u>Despesa '{objetoDespesa.descricao}'</u>`,
  columns: [
    { field: 'suprimento.controleSequencial', header: 'field.controleSuprimento', isDefaultVisible: true },
    { field: 'suprimento.ano', header: 'field.anoSuprimento', isDefaultVisible: true },
    { field: 'fornecedor.nome', header: 'field.fornecedor', isDefaultVisible: true },
    { field: 'objetoDespesa.descricao', header: 'field.objeto', isDefaultVisible: true },
    { field: 'contaCorrente.descricao', header: 'field.contaCorrente', isDefaultVisible: true },
    { field: 'numeroNf', header: 'field.numeroNf', isDefaultVisible: true },
    { field: 'dataNf', header: 'field.dataNf', isDefaultVisible: true, isDate: true, mask: 'dd/MM/yyyy', formatOption: 'DATE' },
    { field: 'valorTotal', header: 'field.valorTotal', isDefaultVisible: true, isMoney: true, formatOption: 'MONETARY' },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default despesaAudit;
