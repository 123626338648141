import { SimNao } from 'src/app/domain/simNao/sim-nao';
import { ValorNatureza } from './../valor-natureza/valor-natureza.model';
import { FormaConcessao } from './../forma-concessao/forma-concessao.model';
import { Agencia } from './../agencia/agencia.model';
import { Suprido } from './../suprido/suprido.model';
import { BaseEntity } from 'src/app/core/domain/base.entity';
import { Origem } from '../origem/origem.model';
import { AcaoOrcamentaria } from '../acao-orcamentaria/acao-orcamentaria.model';
import { Portaria } from '../portaria/portaria.model';
import { Status } from '../status/status.model';

export class Suprimento extends BaseEntity {
  controleSequencial: number;
  ano: number;
  acaoOrcamentaria: AcaoOrcamentaria;
  suprido: Suprido;
  prazoAplic: number;
  portarias: string;
  codigoFechamento: string;
  valorTotalConcedido: number;
  valorBaixa: number;
  classificacao: string;
  saldo: number;
  reembolso: number;
  justificativa: string;
  justificativaAlteracaoPrazo: string;
  parecer: string;
  observacao: string;
  agencia: Agencia;
  sadp: string;
  formaConcessao: FormaConcessao;
  dataDisp: Date;
  dataHomologacao: Date;
  declaracaoPresidente: string;
  inteiroTeor: string;
  prazoPrestConta: number;
  dataLibera: Date;
  dataCancelamento: Date;
  origemLocal: Origem;
  numConta: string;
  ptres: string;
  portaria: Portaria;
  listaValorNatureza: ValorNatureza[];
  status: Status;
  homologa: SimNao;
  numeroDocumentoContabilizacao: string;
}
